import Img from "gatsby-image";

import React, {useState, useCallback} from 'react'
import {useTransition, animated} from 'react-spring'


const FullScreenImage = ({index, imagesList, closeFunction}) => {
    const pages = imagesList.map(img => ({style}) => <animated.div style={{...style}}>
        <div className="full-size-image">
            <Img fluid={img.image.node.childImageSharp.fluid} imgStyle={{objectFit: "contain"}}
                 style={{maxHeight: "100%"}}/>
        </div>
    </animated.div>);
    return (
        <div className="full-screen-overlay show">
            <ImageScroll pages={pages} startIndex={index}/>
            <div role={"button"} tabIndex={"0"} className={"close-icon"} onClick={() => closeFunction()} onKeyDown={() => closeFunction()}/>
        </div>
    )
};

function ImageScroll({pages, startIndex}) {
    const [index, set] = useState(startIndex);
    const [direction, setDirection] = useState(0);
    const length = pages.length;
    const onClick = useCallback((e) => {
        let increment = e.pageX > window.innerWidth / 2 ? 1 : -1;
        set(state => (length + state + increment) % length);
        setDirection(increment);
    }, [length]);
    const transitions = useTransition(index, p => p, {
        from: {opacity: 0, transform: 'translate3d(' + (direction * (100)) + '%,0,0)'},
        enter: {opacity: 1, transform: 'translate3d(0%,0,0)'},
        leave: {opacity: 0, transform: 'translate3d(' + (direction * (-50)) + '%,0,0)'},
    });
    return (
        <div role={"button"} className="simple-trans-main" onClick={onClick} onKeyDown={onClick} tabIndex={"0"}>
            {transitions.map(({item, props, key}) => {
                const Page = pages[item];
                return <Page key={key} style={props}/>
            })}
        </div>
    )
}

export default FullScreenImage;