
import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

function SEO({ description, lang, keywords, title }) {
    return (
        <StaticQuery
            query={detailsQuery}
            render={data => {
                const metaDescription =
                    description || data.site.siteMetadata.description;

                const concatMeta = [
                    {
                        name: `description`,
                        content: metaDescription,
                    },
                    {
                        property: `og:title`,
                        content: title,
                    },
                    {
                        property: `og:description`,
                        content: metaDescription,
                    },
                    {
                        property: `og:type`,
                        content: `website`,
                    },
                    {
                        name: `twitter:card`,
                        content: `summary`,
                    },
                    {
                        name: `twitter:creator`,
                        content: data.site.siteMetadata.author,
                    },
                    {
                        name: `twitter:title`,
                        content: title,
                    },
                    {
                        name: `twitter:description`,
                        content: metaDescription,
                    }
                ]
                    .concat(
                        keywords.length > 0
                            ? {
                                name: `keywords`,
                                content: keywords.join(`, `),
                            }
                            : []
                    );

                return (
                    <Helmet
                        htmlAttributes={{
                            lang,
                        }}
                        title={title || data.site.siteMetadata.title}
                        titleTemplate={`%s`}
                        meta={concatMeta}
                    />
                )
            }}/>
    )
}

SEO.defaultProps = {
    lang: `en`,
    keywords: ['photographer', 'Dubai', 'photography', 'studio', 'photo'],
};

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    keywords: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string,
};

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
        url
        image
      }
    }
  }
`;