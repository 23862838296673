import React from "react"
import { StaticQuery, graphql } from "gatsby"
import GalleryImages from "./gallery-images";

const Gallery = ({ galleryName, balanceToCenter = false, doNotCarryImg=false }) => (
    <StaticQuery
        query={ graphql`
            query {
              allImages: allFile(sort:{
                order: ASC,
                fields: [relativeDirectory]
              },
              filter:{ sourceInstanceName:{eq:"images"}}) {
                edges {
                    node {
                        relativeDirectory,
                        name,
                        childImageSharp {
                                fluid(maxHeight: 1080, quality: 95) {
                                    ...GatsbyImageSharpFluid_noBase64
                                }
                            }
                        }
                    }
            },
            galleryConfig : allConfigJson{
                edges{
                  node {
                    imagesSets{
                        name,
                        desktop {
                            order
                            columns
                        },
                        mobile {
                            order
                            columns
                        }
                    }
                  }
                }
            }
        }`}
        render={data => {
            const images = data.allImages.edges.filter(
                edge => edge.node.relativeDirectory ===  "gallery_" + galleryName
            );

            if (!images) {
                return null;
            }

            let imagesInOrder = setOrder(galleryName,  data.galleryConfig, images);
            return (<GalleryImages images={imagesInOrder} balanceToCenter={balanceToCenter}  doNotCarryImg = {doNotCarryImg} ></GalleryImages>)
        }}
    />
);

function setOrder(galleryName, config, images) {
    let galleryConfig = config.edges[0].node.imagesSets.find(set => set.name === galleryName);
    let galleryImages = images.reduce((map, image) => {
        map[image.node.name] = image;
        return map;
    }, {});

    let imagesInOrder = {
        desktopImages: [],
        mobileImages: []
    };

    function addImages(layout) {
        galleryConfig[layout].order.forEach((name, i) => {
                let galleryImage = galleryImages[name];
                if (galleryImage === undefined) {
                    console.log("Wrong image name: ", name)
                }
                imagesInOrder[layout + 'Images'].push({index: i, image: galleryImage});
            }
        );
    }

    addImages("desktop");
    addImages("mobile");

    return imagesInOrder;
}

export default Gallery