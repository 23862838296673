import React from "react";

import "../../styles/subgallery.css";
import Gallery from "./gallery";
import Img from "gatsby-image";

class Subgallery extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openGallery: ''
        };

        this.sets = props.subgalleryConfig.sets;
        this.toggleGallery = this.toggleGallery.bind(this);
    }

    toggleGallery(galleryToOpen, balanceToCenter = false, doNotCarryImg = false) {
        this.setState({
            openGallery: galleryToOpen,
            balanceToCenter: balanceToCenter,
            doNotCarryImg: doNotCarryImg
        });
    }

    render() {

        let openGallery = '';
        if (this.state.openGallery !== '') {
            openGallery =
                <div className="full-screen-overlay full-screen-img show">
                    <div role={"button"} tabIndex={"0"} className={"close-icon"} onClick={() => this.toggleGallery('')}
                         onKeyDown={() => this.toggleGallery('')}/>
                    <Gallery galleryName={this.state.openGallery} balanceToCenter={this.state.balanceToCenter}
                             doNotCarryImg={this.state.doNotCarryImg}></Gallery>
                </div>;
        }


        function renderDescriptionDiv(index, set, addToClasses) {
            return <div className={"descriptions text" + (index + 1) + " " + addToClasses}>
                <div className="background">
                    <div className="main">{set.labels.main.map(line => {
                        return line.replace(/ /g, "\u00a0") + " ";
                    })
                    }
                    </div>
                    <div className="secondary">{set.labels.secondary}</div>
                </div>
            </div>;
        }

        return (<div className="subgallery">
                <div className="grid-container">
                    <div className="ornaments">
                        <div className={"title-wrapper"}>
                            <div className={"title"}>
                                <h1>YOUR STORY MIGHT GO</h1>
                                <h1>SOMETHING LIKE THIS...</h1>
                            </div>
                            <img alt={""} src={"png/szczalka-subgallery.png"}/>
                        </div>
                    </div>
                    <div className="grid">
                        {this.sets.map((set, index) => {
                            return (
                                <React.Fragment key={"gallery_" + set.name + index}>
                                <div role={"button"} tabIndex={"0"} className={'frame frame' + (index + 1)}
                                     onClick={() => this.toggleGallery(set.name, set.balanceToCenter, set.doNotCarryImg)}
                                     onKeyDown={() => this.toggleGallery(set.name, set.balanceToCenter, set.doNotCarryImg)}>
                                    <Img
                                        fluid={set.imageFluid}
                                        loading="eager"
                                        style={{height: '100%', width: '100%'}} imgStyle={{objectFit: 'cover'}}
                                    />
                                    {renderDescriptionDiv(index, set, "mobile")}
                                </div>
                                {renderDescriptionDiv(index, set, "desktop")}
                                </React.Fragment>
                            )
                        })}
                    </div>
                </div>
                {openGallery}
            </div>

        );
    }
}

export default Subgallery;
