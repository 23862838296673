import React from "react";

import "../styles/footer.css";

class Footer extends React.Component {
  render() {
    return (
      <footer className="page-footer">
          <img alt={""} src="svg/logo.svg"/>
          <div className="text-center">
          <h1 className="text-uppercase mt-3">Photography for adventurous dreamers</h1>
          <h2 className="text-uppercase">Dubai - Worldwide | established 2019</h2>
          </div>
      </footer>
    );
  }
}


export default Footer;