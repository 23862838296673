import React from "react";

import "../styles/about-me.css";
import { Spring } from "react-spring/renderprops";
import VisibilitySensor from "react-visibility-sensor";
import AboutMeImage from "./images/about-me-image";

class AboutMe extends React.Component {

  state = {
    desktop: false
  };

  componentDidMount() {
    this.setState({ desktop: window.innerHeight / window.innerWidth < 1 });
    let isInstagram = navigator.userAgent.match(/instagram/i);
    if (isInstagram) {
        document.querySelector('.motto-wrapper .motto').style.left='-16%'
    }
  }

  render() {
    const mottoHtml = <div className="motto didot-light">
      <div>I</div>
      <div>WANT</div>
      <div>YOU</div>
      <div>
        <div>TO&nbsp;</div>
        <div>LOOK</div>
      </div>
      <div>& FEEL</div>
      <div>amazing</div>
    </div>;


    const motto =
      <VisibilitySensor partialVisibility offset={{ bottom: "700" }} active={this.state.desktop}>
        {({ isVisible }) => (
          <Spring delay={0}
                  to={{ "paddingTop": isVisible ? "70px" : "0px" }}>
            {props =>
              <div style={{ ...props }} className="row">
                <div className="motto-wrapper">
                  {mottoHtml}
                </div>
              </div>
            }
          </Spring>
        )}
      </VisibilitySensor>
    ;

    let title = <div className="row">
      <div className="col text-center">
        <h1 className="section-title">ABOUT ME</h1>
      </div>
    </div>;
    let description = <div className="row">
      <div className="col align-self-center">
        <p>Hi there! I’m Agnes, Dubai based photographer and serial peanut butter eater. I’m
          obsessed with telling
          visual stories and capturing the amazing range of love and emotions in people. I love to
          capture every
          moment in a candid way and creatively document those laughs, tears and kisses for you to
          treasure. Let’s capture you at your very, very best!
        </p>

        <div className="ornaments">


          <VisibilitySensor partialVisibility>
            {({ isVisible }) => (
              <Spring delay={300} to={{
                opacity: isVisible ? 1 : 0,
                transform: isVisible ? "translateX(0)" : "translateX(-200px)"
              }}>
                {props =>
                  <div style={{ ...props }} className="handwriting">Agnes x</div>
                }
              </Spring>
            )}
          </VisibilitySensor>

          <VisibilitySensor partialVisibility>
            {({ isVisible }) => (
              <Spring delay={300}
                      to={{ opacity: isVisible ? 1 : 0, transform: isVisible ? "translateX(0)" : "translateX(200px)" }}>
                {props =>
                  <div style={{ ...props }} className="asset-camera"></div>
                }
              </Spring>
            )}
          </VisibilitySensor>
        </div>


      </div>
    </div>;

    let image = <div className="row image">
      <div className="col align-self-center">
        <div className={"img rounded-circle img-fluid"}> <AboutMeImage></AboutMeImage> </div>
        <div className="handwriting">behind the lens</div>
      </div>
    </div>;
    return (
      <div className="container about-me-container">
        {motto}
        <hr style={{display:"none"}}/>
        {title}
        {image}
        {description}
      </div>
    );
  }
}


export default AboutMe;