import React, { useState, useEffect } from "react";
import { animated as a, useSpring } from "react-spring";
import "../styles/contactForm.css";

const targetEmail = "stankiewicz.photography@gmail.com";
const emailApi = "https://hmdm3bo4p1.execute-api.eu-west-1.amazonaws.com/test";


function ContactForm() {
  const [flipped, set] = useState(false);
  const { transform, opacity} = useSpring({
    opacity: flipped ? 1 : 0,
    transform: `perspective(600px) rotateY(${flipped ? 180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 80 }
  });

  useEffect(setAfterMsgDivHeight, []);

  let name = React.createRef();
  let email = React.createRef();
  let msg = React.createRef();

  function setAfterMsgDivHeight(){
      document.querySelector('.contact-container .after-msg').style.height = document.querySelector('.contact-form').offsetHeight + 'px';
  }

  function onClick(form) {
    set(state => !state);
    form.preventDefault();

    if (flipped){
      email.current.value = name.current.value = msg.current.value = "";
    } else {
      sendEmail(email.current.value, name.current.value, msg.current.value);
    }
    document.querySelector(".contact-container .button button").textContent = flipped ? "Send": "New message";
    return false;
  }

  function sendEmail(senderEmail, senderName, msgText) {
    const subject = "Koala page message from " + senderName;

    let bodyJson = {
      personalizations: [{ to: [{ email: targetEmail }] }],
      from: { email: senderEmail },
      subject: subject,
      content: [{ type: "text/plain", value: msgText }]
    };


    fetch(emailApi, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(bodyJson)
    }).then(value => console.log("RESULT: ", value))
      .catch((error) => {
        console.error("ERROR: ", error);
      });
  }

  return (
    <div className={"contact-form"}>
      <a.div style={{ opacity, transform: transform.interpolate(t => `${t} rotateY(180deg)`) }}>
        <div style={{ "position": "absolute", "width": "100%", "height": "100%" }}>
          <div className={"after-msg"}>
            <img className={"envelope"} src={"png/envelope.svg"} alt={""}/>
            <h1 className="handwriting">Thank you! I will get in touch soon!</h1>
          </div>
        </div>
      </a.div>
      <a.div style={{ opacity: opacity.interpolate(o => 1 - o), transform }}>
        <form id={"contact-form"} onSubmit={onClick}>
          <h1 className="handwriting">Tell me everything</h1>
          <div className="fields">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              name="name"
              id={"name"}
              ref={name}/>

            <label htmlFor="email">Email</label>
            <input
              type="text"
              name="email"
              id = "email"
              ref={email}/>

            <label htmlFor="msg">Message</label>
            <textarea
              type="text"
              name="msg"
              id="msg"
              ref={msg}
              rows="6"
              cols="50"/>
          </div>
        </form>
      </a.div>
    </div>
  );
}

export default ContactForm;