import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image";


const YourStoryImage = ({ galleryName='test' }) => (
    <StaticQuery
        query={ graphql`
            query {
                file(relativePath: { eq: "your-story/your story is waiting.jpg" }) {
                  childImageSharp {
                    fluid(maxHeight: 1080, quality: 95) {
                        ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
            }`
        }
        render={data => {
            return (<Img   imageStyle={{objectFit: `contain`}} fluid={data.file.childImageSharp.fluid} />)
        }}
    />
);

export default YourStoryImage