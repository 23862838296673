import { useEffect, useState } from "react";

function isMobileResolution(switchValue) {
  return window.outerWidth <= switchValue;
}

function ResponsiveLayout(params) {

  let {switchWidth, layout1, layout2} = params;

  const [isMobile, setIsMobile] = useState(false);
  let switchValue = switchWidth;

  useEffect(() => {
    setIsMobile(isMobileResolution(switchValue));
    window.addEventListener("resize", () => {
      if (!isMobileResolution(switchValue) && isMobile) {
        setIsMobile(false);
      } else if (isMobileResolution(switchValue) && !isMobile) {
        setIsMobile(true);
      }
    });
  },[switchValue, isMobile]);
  return (isMobile ? layout2 : layout1);
}

export default ResponsiveLayout;