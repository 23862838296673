import React from "react";

import "../../styles/gallerySection.css";
import FullScreenImage from "./../full-screen-image";
import Img from "gatsby-image";

class GallerySection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            imageToOpen: ''
        };
        this.showFullImg = this.showFullImg.bind(this);

        let images = this.props.images;
        let balanceToCenter = this.props.balanceToCenter;
        let doNotCarryImg = this.props.doNotCarryImg;

        this.desktopImages = images.desktopImages;
        this.desktopBalancer = new ColumnBalancer(3, this.desktopImages, balanceToCenter, doNotCarryImg);

        this.mobileImages = images.mobileImages;
        this.mobileBalancer = new ColumnBalancer(1, this.mobileImages, true);

        this.imagesInPreviwOrder = this.desktopBalancer.getImagesInPreviwOrder();
    }

    showFullImg(type, index) {
        if (type === "mobile") {
            return;
        }

        this.setState({
            imageToOpen: <FullScreenImage index={index}
                                          imagesList={this.imagesInPreviwOrder}
                                          closeFunction={() => this.closeFullImg()}/>
        });
    }

    closeFullImg() {
        this.setState({
            imageToOpen: null
        });
    }

    render() {
        let openImage = '';
        if (this.state.imageToOpen) {
            openImage = this.state.imageToOpen;
        }

        let gallery = <div className="gallery">
            {this.desktopBalancer.columns.map((col, index) =>
                this.renderColumn(col, "desktop", index)
            )}

            {this.mobileBalancer.columns.map((col, index) =>
                this.renderColumn(col, "mobile", index)
            )}
        </div>;


        return (<div className="gallery-section">
                {openImage}
                {gallery}
            </div>
        );
    }

    renderColumn(col, wrapperClass, index) {
        return <div key={wrapperClass + index} className={"gallery-column " + wrapperClass}>
            {col.map(item => {
                    let key = wrapperClass + index + item.image.node.name;
                    return (
                        <div key={key} className="img" id={key} role={"button"} tabIndex={"img" + index}
                             onKeyDown={() => this.showFullImg(wrapperClass, item.imageFlowIndex)}
                             onClick={() => this.showFullImg(wrapperClass, item.imageFlowIndex)}>
                            <Img
                                fluid={{
                                    ...item.image.node.childImageSharp.fluid,
                                    sizes: '(max-width: 767px) 100vw, (min-width: 768px) 35vw'
                                }}
                                loading="eager"
                                onLoad={() => document.getElementById(key).classList.add("loaded")}
                            />
                        </div>)
                }
            )}
        </div>;
    }
}

export default GallerySection;


class ColumnBalancer {
    constructor(size, imagesOrg, balanceToCenter = false, doNotCarryImg = false) {
        this.balanceToCenter = balanceToCenter;
        this.doNotCarryImg = doNotCarryImg;

        this.noOfImages = imagesOrg.length;
        const images = [...imagesOrg];
        if (size === 0) {
            return;
        }
        if (!images || images.length === 0) {
            console.log("Empty images!");
            return;
        }
        let heightSum = images
            .map(img => this.getHeightFactor(img))
            .reduce((total, next) => total + next);

        if (size === 1) {
            this.columns = [images];
            return;
        }

        let loadPerColumn = heightSum / size;

        let currColumn = [];
        this.columns = [currColumn];
        let currColSize = 0;
        while (images.length > 0) {
            let img = images.shift();
            currColumn.push(img);
            currColSize = currColSize + this.getHeightFactor(img);
            if (currColSize > loadPerColumn) {
                let nextColumn = [];
                currColSize = 0;
                if ((this.balanceToCenter || this.columns.length !== 1) && images.length !== 0 && !this.doNotCarryImg) {
                    let lastImage = currColumn.pop();
                    let lastElementHeight = this.getHeightFactor(lastImage);
                    currColSize = lastElementHeight;
                    nextColumn.push(lastImage);
                }
                this.columns.push(nextColumn);
                currColumn = nextColumn;
            }
        }
        if (this.columns[this.columns.length - 1].length === 0) {
            this.columns.pop();
        }
    }

    getImagesInPreviwOrder() {
        let totalColumnsLength = 0;
        this.columns.forEach(column => totalColumnsLength = totalColumnsLength + column.length);
        if (totalColumnsLength !== this.noOfImages) {
            throw new Error("Wrong number of images ( images in columns: " + totalColumnsLength + " noOfImagesSet: " + this.noOfImages);
        }

        let result = [];
        let maxColIndex = this.columns.length;

        let heightPoinsters = new Array(this.columns.length).fill(0);
        let columnsClone = [];
        for (let i = 0; i < this.columns.length; i++) {
            columnsClone[i] = this.columns[i].slice();
        }
        let i = 0;

        while (this.noOfImages > result.length) {
            let column = columnsClone[i];
            let nextColIndex = (i + 1) % maxColIndex;
            if (column.length === 0) {
                i = nextColIndex;
                continue;
            }
            do {
                let nextImg = column.shift();
                nextImg.imageFlowIndex = result.length;
                result.push(nextImg);
                heightPoinsters[i] = heightPoinsters[i] + this.getHeightFactor(nextImg) * 1.0;
            } while (column.length > 0 && heightPoinsters[i] < this.getHeightFactor(columnsClone[nextColIndex][0]) * 0.5 + heightPoinsters[nextColIndex])
            i = nextColIndex;
        }

        return result;
    }

    getHeightFactor(img) {
        if (img === undefined) {
            return 0;
        }
        return 1 / img.image.node.childImageSharp.fluid.aspectRatio;
    }
}