import React from "react";
import Navbar from "../components/navbar.js";
import Slider from "../components/slider.js";
import AboutMe from "../components/about-me.js";
import Parallax from "../components/parallax.js";
import Pricing from "../components/pricing";
import Gallery from "../components/gallery/gallery-section";
import Footer from "../components/footer";
import Contact from "../components/contact";
import YourStory from "../components/your-story";
import Instagram from "../components/instagram";
import {graphql} from "gatsby";
import SEO from "../components/SEO";

export default ({ data }) => <div className="page-wrapper">
  <SEO/>
  <Navbar/>
  <Slider images={data.slider}/>
  <section><AboutMe/></section>
  <Parallax image={data.parallax.edges[0].node} textColor="black" class="shift-on-mobile"/>
  <section><Gallery/></section>
  <Parallax image={data.parallax.edges[1].node} textColor="white"/>
  <section><Pricing/></section>
  <Parallax image={data.parallax.edges[2].node} textColor="white"/>
  <section><Contact/></section>
  <section><YourStory/></section>
  <section><Instagram images={data.instagram}/></section>
  <Footer/>
</div>


export const fluidImage = graphql`
  fragment fluidImage on File {
  childImageSharp {
  fluid(maxHeight: 1080, quality: 95) {
  ...GatsbyImageSharpFluid_noBase64
}
}
}
  `;

export const fluidImageSmall = graphql`
  fragment fluidImageSmall on File {
  childImageSharp {
  fluid(maxHeight: 400, quality: 95) {
  ...GatsbyImageSharpFluid_noBase64
}
}
}
  `;

export const fixedImage = graphql`
  fragment fixedImage on File {
  childImageSharp {
  fluid(maxWidth: 1920, quality: 90) {
  ...GatsbyImageSharpFluid_noBase64
}
}
}
  `;



export const query = graphql`
  query {
  slider: allFile(sort:{order: ASC, fields: [name]}, filter:{relativeDirectory:{eq:"slider"}, sourceInstanceName:{eq:"images"}}) {
  edges {
  node {
  name,
  ...fluidImage
}
}
},
  parallax: allFile(sort:{order: ASC, fields: [name]}, filter:{relativeDirectory:{eq:"parallax"}, sourceInstanceName:{eq:"images"}}) {
  edges {
  node {
  name,
  ...fixedImage
}
}
},
  instagram: allFile(sort:{order: ASC, fields: [name]}, filter:{relativeDirectory:{eq:"instagram"}, sourceInstanceName:{eq:"images"}}) {
  edges {
  node {
  name,
  ...fluidImageSmall
}
}
}


}
  `;