import React from "react";

import $ from "jquery";

import "../styles/slider.css";
import Image from "gatsby-image";

class Slider extends React.Component {

    constructor(props) {
        super(props);
        this.sliderImages = this.props.images.edges.map(item => {
            return {
                name: item.node.name,
                aspectRatio: item.node.childImageSharp.fluid.aspectRatio,
                fluid: item.node.childImageSharp.fluid
            };
        });
    }

    render() {
        let expandedImagesList = this.sliderImages
            .slice(-2)
            .concat(this.sliderImages)
            .concat(this.sliderImages.slice(0, 4));

        return (
            <div className="container-fluid full-width slider">
                <div className="fil-screen">
                    <div className="slider-wrapper">
                        <div className="slider-container" style={{display: 'none'}}>
                            {expandedImagesList.map((item, index) =>
                                <div className="slider-item" id={item.name} key={index}>
                                    <Image style={{
                                        position: "relative",
                                        overflow: "unset"
                                    }} fluid={item.fluid} loading="eager" fadein={false}/>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        setTimeout(() => {
                let slider = new SliderLogic(this.sliderImages);
                slider.init();
                $(window).on("layout-resize", function () {
                    slider.adoptToScreen();
                });
            }
            , 0);
    }
}

class SliderLogic {
    constructor(images) {
        this.$container = null;
        this.positions = [];
        this.currentIndex = 0;
        this.images = images;
    }
}

SliderLogic.prototype.moveTo = function (newPosition, time) {
    if (!time) {
        time = 0;
    }

    this.$container.css("transition-duration", time + "ms");
    this.$container.css("transform", "translateX( " + newPosition + "px)");
};

SliderLogic.prototype.realWidth = function (index) {
    let width = $(".slider-container").height() * this.images[index].aspectRatio;
    return width;
};

SliderLogic.prototype.init = function () {
    this.$container = $(".slider-container");
    this.updateImgContainerSizes();

    this.currentIndex = 0;
    this.updatePositions();
    this.moveTo(this.positions[0]);
    this.$container.show();

    let self = this;
    this.shiftTimeout = setTimeout(function () {
        self.moveByOne(self);
    }, 500);
};


SliderLogic.prototype.updateImgContainerSizes = function() {
    this.$container.children().each((i, imageDiv) => {
        let imageName = imageDiv.id;
        let index = this.images.findIndex(element => imageName === element.name);
        $(imageDiv).css("width", this.realWidth(index));
        $(imageDiv).css("height", this.$container.height());
    });
};

SliderLogic.prototype.reset = function () {
    this.moveTo(this.positions[0], 0);
    this.currentIndex = 0;
};

SliderLogic.prototype.moveByOne = function (slider) {
    this.moveTo(this.positions[this.currentIndex + 1], 5000);
    this.currentIndex++;
    let nextTimeout = 5000;
    if (this.currentIndex === this.images.length + 1) {
        this.reset();
        nextTimeout = nextTimeout / 3;
    }
    this.shiftTimeout = setTimeout(function () {
        slider.moveByOne(slider);
    }, nextTimeout);
};


SliderLogic.prototype.updatePositions = function () {
    let addedItemWidth = this.realWidth(this.images.length - 1) + this.realWidth(this.images.length - 2);
    let firstItemWidth = this.realWidth(0);

    this.positions[0] = ($(window).width() - firstItemWidth) / 2 - addedItemWidth;
    for (let index = 1; index <= this.images.length; index++) {
        let currentItemWidth = this.realWidth(index % this.images.length);
        let prevPosition = this.realWidth(index - 1);
        this.positions[index] = this.positions[index - 1] - (currentItemWidth + prevPosition) / 2;
    }
};

SliderLogic.prototype.adoptToScreen = function(){
    this.updateImgContainerSizes();
    this.updatePositions();
    clearTimeout(this.shiftTimeout);
    let self = this;
    this.shiftTimeout = setTimeout(function () {
        self.moveByOne(self);
    }, 500);

};


export default Slider;