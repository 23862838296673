import React from "react";

import "../styles/price-package.css";

class PricePackage extends React.Component {
  render() {
    let packageData = this.props.packageData;
    return (
      <div className="price-package" key={packageData.price}>
          <div className="title" style={{backgroundImage:packageData.titleBackground}}>
            <h1>{packageData.name}</h1>
            <h4 className="">{packageData.subName}</h4>
          </div>
          <div className="price">
            <h1>{packageData.price} AED</h1>
          </div>
          <div className="description">
            {packageData.description.map((line, index) => <p key={index}>{line}</p>)}
          </div>
          <div className={"separator"}></div>
      </div>
    );
  }
}


export default PricePackage;