import React from "react";

import "../../styles/gallery-section.css";
import ConfiguredSubgallery from "./configured-subgallery"
import GalleryMainImage from "../images/gallery-main-image";


import $ from "jquery";

class GallerySection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openGallery: ''
        };
        this.toggleGallery = this.toggleGallery.bind(this);
    }

    componentDidMount() {
        window.galleryComponent = this;

        let self = this;
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', function (event) {
            window.history.pushState(null, document.title, window.location.href);
            self.toggleGallery('');
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.openGallery !== "") {
            $('body').addClass("no-scroll");
            return;
        }
        $('body').removeClass("no-scroll");
        setTimeout(() =>
            window.scrollTo(0, this.state.previousScrollPosition), 200);
    }


    toggleGallery(galleryToOpen, balanceToCenter = false) {
        this.setState({
            openGallery: galleryToOpen,
            balanceToCenter: balanceToCenter,
            previousScrollPosition: window.scrollY
        });
    }

    render() {
        let openGallery = '';
        if (this.state.openGallery !== '') {
            openGallery =
                <div className="full-screen-overlay full-screen-img show">
                    <div role="button" className={"close-icon"} onClick={() => this.toggleGallery('')}
                         onKeyDown={() => this.toggleGallery('')} tabIndex={"0"}/>
                    <ConfiguredSubgallery name={this.state.openGallery}></ConfiguredSubgallery>
                </div>;
        }
        return (
            <div className="container gallery-container py-5">
                <div className="gallery-wrapper">
                    <GalleryMainImage/>
                    <div className="gallery-sections">
                        <div className="title"><h1>PORTFOLIO</h1></div>
                        <div className="buttons-wrapper">
                            <div role="button" className="button couples" onClick={() => this.toggleGallery('couples')}
                                 onKeyDown={() => this.toggleGallery('couples')} tabIndex={"0"}>
                                Couple Gallery
                            </div>
                            <div role="button" className="button people" onClick={() => this.toggleGallery('people')}
                                 onKeyDown={() => this.toggleGallery('people')} tabIndex={"0"}>
                                People Gallery
                            </div>
                            {/*FIXME: move balance to center to the config*/}
                            <div role="button" className="button events" onClick={() => this.toggleGallery('events', true)}
                                 onKeyDown={() => this.toggleGallery('events', true)} tabIndex={"0"}>
                                Event Gallery
                            </div>
                        </div>
                    </div>

                    <div id="galleries-parent">
                        {openGallery}
                    </div>
                </div>
            </div>
        )
    }
}

export default GallerySection

