import React from "react";
import ReactDOM from "react-dom";

import "../styles/parallax.css";
import Img from "gatsby-image";

class Parallax extends React.Component {

  constructor(params) {
    super(params);
    this.visible = true;

    this.setBackgroundHeight = this.setBackgroundHeight.bind(this);
  }

  getElementPosition() {
    return this.parallaxNode.getBoundingClientRect().top;
  }

  handleScroll() {
    this.getElementPosition();
    let currPosition = this.parallaxNode.getBoundingClientRect().top;

    if (!this.visible && this.isParallaxVisible(currPosition)) {
      this.visible = true;
      this.toggleBackground();
    } else if (this.visible && !this.isParallaxVisible(currPosition)) {
      this.visible = false;
      this.toggleBackground();
    }
  }

  toggleBackground() {
    this.parallaxNode.getElementsByClassName("background")[0].classList.toggle("hide");
  }

  isParallaxVisible(currPosition) {
    return window.innerHeight > currPosition && this.parallaxNode.getBoundingClientRect().bottom >= 0;
  }

  setBackgroundHeight() {
    let style = this.parallaxNode.getElementsByClassName("background")[0].style;
    style.height = (window.innerHeight * 1.1) + "px";
  }

  componentDidMount() {
    this.parallaxNode = ReactDOM.findDOMNode(this);
    this.initialPosition = this.getElementPosition();
    window.addEventListener("scroll", () => this.handleScroll(this.initialPosition));

    this.setBackgroundHeight();
    window.addEventListener("layout-resize", this.setBackgroundHeight);
  }

  render() {
    return (
      <div>
        <div className={"parallax " + this.props.class }>
          <div className={"background"} >
            <Img fluid={this.props.image.childImageSharp.fluid} loading="eager" fadein={false} style={{height:'100%', width:'100%'}} imgStyle={{ objectFit: 'cover' }}/>
          </div>
          <div className="moving-layer text" style={{ color: this.props.textColor }}>Your&nbsp;love,
            your&nbsp;adventure,
            your&nbsp;story
          </div>
        </div>
      </div>
    );
  }
}


export default Parallax;