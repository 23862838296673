import React from "react";

import "../styles/contact.css";
import ContactForm from "./contactForm";
import ContactImage from "./images/contact-image";

export default() =>
    <div className="container contact-container">
                <div className="row">
                    <div className="col-12 text-center">
                        <h1 className="section-title">Say hello</h1>
                        <h1 className="handwriting">Let’s Adventure, Laugh, Cry & Be Friends!</h1>
                        <h4>I can't wait to hear from you!</h4>
                    </div>
                </div>
                <div className="row contact-info">
                    <div className="col-md-7 order-md-1 order-2">
                        <table>
                            <tbody>
                            <tr>
                                <td><h4 className="icon email">stankiewicz.photography@gmail.com</h4></td>
                            </tr>
                            <tr>
                                <td><h4 className="icon phone"> +971 58 580 6049</h4></td>
                            </tr>
                            <tr>
                                <td><h4 className="icon whats-app">+48 793 052 588</h4></td>
                            </tr>
                            </tbody>
                        </table>
                        <ContactForm></ContactForm>

                    </div>
                    <div className="col-md-5 order-md-2 order-1 align-end">
                        <div className="image-wrapper">
                            <div className="center">
                                <div className="overlay didot-light">
                                    <div>get</div>
                                    <div>in</div>
                                    <div>touch</div>
                                </div>
                            </div>
                            <ContactImage/>
                        </div>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'button'}>
                        <button type="submit" form={'contact-form'} onClick={()=>document.querySelector('.contact-container .button h1.handwriting').classList.toggle("hidden")}>Send</button>
                        <h1 className="handwriting text-center">love, Agnes xo xo</h1>
                    </div>
                </div>
            </div>