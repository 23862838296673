import React from "react";

import "../styles/your-story.css";
import ResponsiveLayout from "./util/responsiveLayout";
import YourStoryImage from "./images/your-story-image";

class YourStory extends React.Component {
    render() {
        let buttonRelocateWidth = 766;
        let button = <button onClick={()=>window.galleryComponent.toggleGallery('couples')}>view the whole shoot  </button>;
        return (
            <div className="container-fluid your-story-container">
                <div className="row">
                    <div className="your-story-wrapper">
                        <div className="overlay">
                            <div className="text-field-box">
                                <svg viewBox="0 0 45 14">
                                    <text x="0" y="12" fontWeight="100" fontFamily="Didot-Light">YOUR</text>
                                </svg>
                                <svg viewBox="0 0 52 14">
                                    <text x="0" y="12" fontWeight="100" fontFamily="Didot-Light">STORY</text>
                                </svg>
                            </div>
                            <h5>is waiting to be told</h5>
                            <div className="ornaments" >
                                <div className="handwriting">
                                    <h1>Kate & Jimmy</h1>
                                    <h1>in Dubai</h1>
                                </div>
                                <div className="arrow" />
                            </div>
                            <ResponsiveLayout layout1={button} layout2={''} switchWidth={buttonRelocateWidth}/>
                        </div>
                        <div className="image-wrapper">
                            <YourStoryImage/>
                        </div>
                        <ResponsiveLayout layout1={''} layout2={button} switchWidth={buttonRelocateWidth}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default YourStory;