import React from "react";

import "../styles/navbar.css";
import $ from "jquery";


class Navbar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {addClass: ""};
    }

    componentDidMount() {
        window.addEventListener('scroll', (event) => this.handleScroll());
        window.test = this.getNavHeightWhilScrolling;
        let self = this;
        $('a.scroll-link').on('click', function (e) {
            e.preventDefault();
            self.scrollTo($(this));
            $('.navbar-collapse').collapse('hide');
        });
    }

    componentWillUnmount() {

    }

    handleScroll() {
        let triggerVal = 50;
        if (window.scrollY > triggerVal && this.state.addClass === "") {
            this.setState({
                addClass: "scrolling"
            });
        } else if (window.scrollY < triggerVal && this.state.addClass !== "") {
            this.setState({
                addClass: ""
            });
        }
    }


    getNavHeightWhilScrolling() {
        let $nav = $("nav").clone();
        $nav.css("z-index","-1000");
        $nav.addClass("scrolling");
        $nav.children(".navbar-collapse").removeClass("show");
        $("body").append($nav);
        let height = $nav.outerHeight();
        $nav.remove();
        console.log(height);
        return height;
    }

    scrollTo(clickedLink) {
        let elementClass = clickedLink.attr('href').replace('#', '.');
        let scrollTo = 0;

        if (elementClass !== '.top-content') {
            let nav_height = this.getNavHeightWhilScrolling();
            elementClass += '-container';
            scrollTo = $(elementClass).offset().top - nav_height;
        }
        if ($(window).scrollTop() !== scrollTo) {
            $('html, body').stop().animate({scrollTop: scrollTo}, 1000);
        }
    }


    render() {
        return (
            <nav className={"navbar navbar-expand-lg navbar-light fixed-top " + this.state.addClass}>
                <div className="navbar-brand didot-light">Agnieszka&nbsp;Stankiewicz <span className="city">&nbsp;|&nbsp;Dubai</span></div>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item active">
                            <a className="nav-link scroll-link" href="#top-content">HOME</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link scroll-link" href="#about-me">ABOUT ME</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link scroll-link" href="#gallery">GALLERY</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link scroll-link" href="#pricing">PRICING</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link scroll-link" href="#contact">CONTACT</a>
                        </li>

                    </ul>
                </div>
            </nav>
        )
    }
}


export default Navbar