import React from "react";

import "../styles/pricing.css";
import PricePackage from "./price-package";

class Pricing extends React.Component {
    render() {
        return (
            <div className="container pricing-container">
                <div className="row title-wrapper">
                    <div className="col-12 text-center">
                        <h1 className="section-title">Let's talk about money</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col">

                        <p className="text-justify">
                            I know, talking about costs is usually awkward, but it doesn't have to be! Transparency is
                            trust, and trust is the key to any relationship. So, to lessen your stress, I lay it all out
                            for you to browse. These three clean-cut options let you inquire and book quickly and
                            easily, so you can focus on what matters most: making memories. Need something custom to fit
                            your budget? Don't worry, I've got you covered, just submit an inquiry with all the details,
                            and we'll get to working.
                        </p>
                    </div>
                </div>

                <div className="row">
                    <div className="col align-self-center packages-wrapper">
                        {pricePackages.map((value, index) => <PricePackage key={index} packageData={value}/>)}
                    </div>
                </div>
            </div>
        );
    }
}

const pricePackages = [
    {
        name: "mini session",
        subName: "short & sweet",
        price: "700",
        description: ["30 minutes shooting", "15 edited hi-res pictures"],
        titleBackground: 'url("png/price-bacground_1.png")'
    },
    {
        name: "one-hour session",
        subName: "balanced & elegant",
        price: "1200",
        description: ["60 minutes shooting", "25 edited hi-res pictures"],
        titleBackground: 'url("png/price-bacground_2.png")'
    },
    {
        name: "two-hour session",
        subName: "full-bodied & rich",
        price: "1900",
        description: ["120 minutes shooting", "50 edited hi-res pictures", "two locations"],
        titleBackground: 'url("png/price-bacground_3.png")'
    }
];


export default Pricing;