import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Subgallery from "./subgallery"
import Gallery from "./gallery"

const ConfiguredSubgallery = ({ name }) => (
    <StaticQuery
        query={ graphql`
            query {


                allImages: allFile(sort:{
                    order: ASC,
                    fields: [relativeDirectory]
                  },
                  filter:{ sourceInstanceName:{eq:"images"}}) {
                    edges {
                        node {
                            relativeDirectory,
                            name,
                            childImageSharp {
                                    fluid(maxHeight: 1080, quality: 95) {
                                        ...GatsbyImageSharpFluid_noBase64
                                    }
                                }
                            }
                        }
                },

                galleryConfig : allConfigJson{
                    edges{
                      node {
                           galleries{
                              name
                              sets{
                                name,
                                image,
                                balanceToCenter,
                                doNotCarryImg,
                                labels{
                                  main,
                                  secondary
                                }
                              }
                           }
                      }
                    }
                }
        }`}
        render={data => {
            let subgalleryConfig = data.galleryConfig.edges[0].node.galleries.find(gallery => name === gallery.name);
            subgalleryConfig.sets.forEach(set => {
                let imagePath = set["image"].split('/');
                let image = data.allImages.edges
                    .filter(edge =>
                        edge.node.relativeDirectory ===  'gallery_' + imagePath[0]
                        && edge.node.name ===  imagePath[1])[0].node.childImageSharp.fluid;
                set["imageFluid"] = image;
            });

            if (subgalleryConfig.sets.length === 1){
                let set = subgalleryConfig.sets[0];
                return (<Gallery galleryName = {set.name} balanceToCenter={set.balanceToCenter} doNotCarryImg = {set.doNotCarryImg} ></Gallery>)
            } else {
                return (<Subgallery subgalleryConfig = {subgalleryConfig}></Subgallery>)
            }
        }}
    />
);

export default ConfiguredSubgallery;