import React from "react";

import "../styles/instagram.css";
import Img from "gatsby-image";
import ResponsiveLayout from "./util/responsiveLayout";

class Instagram extends React.Component {

    constructor(props) {
        super(props);
        this.images = this.props.images.edges.map(item => {
            return {
                fluid: item.node.childImageSharp.fluid
            };
        });
    }


    render() {
        return (
            <div className="container-fluid instagram-container">
                <div className="row instagram-images">
                    <div className="img-container">
                        <ResponsiveLayout layout1={this.generateImages(0)} layout2={this.generateImages(2)} switchWidth={766}/>
                        <div className="text-wrapper">
                        <div className="text">
                            <div>you<span className="white">r</span></div>
                            <div>story begins</div>
                            <div>right here</div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    generateImages(arrowIndex) {
        return this.images.slice(0, 3).map((image,index) =>
            <div className="img-wrapper" key={index}>
                { arrowIndex === index ? ARROW : ""}
                <Img fluid={image.fluid}/>
            </div>
        )
    }
}



const ARROW = <div className="ornaments-wrapper"> <div className="ornaments">
    <div className="handwriting">
        <div>
            follow on instagram
        </div>
        <div className="link">
            <a href="https://www.instagram.com/dubai.love.story/" rel="noopener noreferrer" target="_blank">@dubai.love.story</a>
        </div>
    </div>
    <div className="arrow"/>
    </div>
</div>

export default Instagram;